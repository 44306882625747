import { FC, useRef, useContext, useEffect } from 'react';

import { Flex, Spacer, Text, Button } from '@chakra-ui/react'

import { AppState } from "../AppState"

interface Props {
  onAccountClick: () => void
  links?: Array<NavLink>
  mode?: "admin" | "normal"
}

interface NavLink {
  text: string
  action: () => void
}

const TopBar: FC<Props> = ({ onAccountClick, links = [], mode = "normal" }:Props) => {
  const { username } = useContext(AppState);
  const titleRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);

  const scroll = (y:number) => window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth'
  })

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!barRef.current || !titleRef.current) return;
      if (window.scrollY >= window.innerHeight * 2/3) {
        barRef.current.style.backgroundColor = "rgba(0,0,0,.7)";
        titleRef.current.style.opacity = "1";
      } else {
        barRef.current.style.backgroundColor = "transparent";
        titleRef.current.style.opacity = "0";
      }
    })
  })

  useEffect(() => window.removeEventListener('scroll', () => {}))

  return (
    <Flex ref={barRef} className="TopBar" height="56px" justify="center" zIndex="999" transition="all .25s ease">
        <Flex mx="2" w="100%">
          <Flex ref={titleRef} lineHeight="56px" onClick={() => scroll(0)} cursor="pointer" opacity="0" transition="all .25s ease">
            <Text as="aside" fontWeight="bold">EtheRPG Online</Text>
          </Flex>
          <Spacer />
          { links.map(link => <Flex key={links.indexOf(link)}
              className="noMobile"
              lineHeight="56px"
              mx="4"
              onClick={link.action} cursor="pointer"
              _hover={{borderBottom: "2px solid white"}} as="button"
            ><Text>{link.text}</Text>
            </Flex>
          )}
          <Button onClick={onAccountClick} colorScheme="whiteAlpha" fontSize="lg" fontWeight="bold" mt="2">{ mode === "normal" ? (username ? "Your Games" : "Start now") : "Home" }</Button>
        </Flex>
    </Flex>
  );
}

export default TopBar;