import { createContext } from 'react';

export interface Game {
  id: number
  name: string
  path: string
  token?: string
}

export interface StateType {
  username: string | null
  jwt: string | null
  games: Array<Game>
  isDebug: boolean
}

export const initialSession:StateType = {
  username: null,
  jwt: null,
  games: [],
  isDebug: window.location.href.includes('?debug')
}

export const AppState = createContext(initialSession);