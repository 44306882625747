import { FC, useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import { Container, Stack, Flex, Spacer, Text, Button } from '@chakra-ui/react';

import { AppState } from "../AppState"

import TopBar from "../components/TopBar";

const Admin: FC = () => {

  const { username } = useContext(AppState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!username || !username.length) navigate('/')
  })

  return (<>
    <TopBar links={[
      {text:'Reports', action: () => null},
    ]} onAccountClick={() => navigate('/')} mode="admin" />
    <Flex
      className="PageSection"
      minH="100vh"
      bg="black"
      color="white"
      justify="center"
      direction="column"
    >
      <Container>
        <Flex>
          <Stack>
            <Text as="h1" fontSize="5xl" my="4" fontWeight="bold" color="white">Admin</Text>

          </Stack>
        </Flex>
      </Container>
    </Flex>
  </>);
}

export default Admin;