import { FC, useContext } from "react";

import { AppState } from "../AppState"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Flex,
  Image,
  Text,
  Button,
  Spacer
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  closeBtn?: boolean
  onLogin: () => void
  onClose: () => void
}

const LoginModal: FC<Props> = ({ isOpen, closeBtn = true, onLogin, onClose }:Props) => {
  const { isDebug } = useContext(AppState);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justify="center">
            <Flex>
              <Image src="./logo.png" h="86px" w="86px" borderRadius="50%" mr="8" />
              <Text as="h1" fontSize="2xl" textAlign="left" mt="2" fontWeight="bold">Welcome to <br/>EtheRPG Online</Text>
            </Flex>
          </Flex>
        </ModalHeader>
        { closeBtn && <ModalCloseButton />}
        <ModalBody pb="6">
          <Stack className="login">
            <Text py="2" textAlign="center" fontWeight="bold">Connect with</Text>
            <Button w="100%" h="10vh" size="lg" onClick={onLogin} colorScheme="green">Google</Button>
            <Button w="100%" h="10vh" size="lg" onClick={onLogin} colorScheme="facebook">Facebook</Button>
            { isDebug && <Button w="100%" size="lg" onClick={onLogin} colorScheme="red">Instagram</Button>}
            { isDebug && <Button w="100%" size="lg" onClick={onLogin} colorScheme="yellow">TikTok</Button>}
            { isDebug && <Button w="100%" size="lg" onClick={onLogin} colorScheme="orange">Metamask</Button>}
            <Spacer pt="2" />
            <Button onClick={onClose} w="100%">Cancel</Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoginModal;