import { FC, useContext } from "react";
import { AppState } from "../AppState"

import { useNavigate } from "react-router-dom";

import { Stack, Text, Flex, Button, Avatar } from "@chakra-ui/react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faGear, faPuzzlePiece, faHouse, faSplotch } from '@fortawesome/free-solid-svg-icons'

import { Game } from "../AppState"

interface Props {
  selectId?: number | null
  onSelectGame: (id:number) => void
  onCreate: () => void
  onDownload: () => void
  onSettings: () => void
  isMobile?: boolean
}



const Menu: FC<Props> = ({ selectId, onSelectGame, onCreate, onDownload, onSettings, isMobile }: Props) => {
  const { username, games } = useContext(AppState);
  const navigate = useNavigate();

  const homepage = () => {
    navigate('/');
    requestAnimationFrame(() => window.scrollTo({ top: 0, left: 0 }));
  }

  const isSelected = (game?:Game):boolean => !!(game && selectId === game.id);

  return(
    <Stack h="100%">
      <Stack mb="2">
        <Text fontSize="xl" fontWeight="bold">Your games</Text>
      </Stack>
      <Flex direction="column" height="100%" overflowY="auto" p="2">
        { games.map(game => (
            <Button
              className={`GameButton ${isSelected(game) ? 'selected' : ''}`}
              key={games.indexOf(game)}
              mb="2"
              border="1px solid #ccc"
              p="4"
              w="100%"
              background="white"
              rightIcon={isSelected(game) ? <FontAwesomeIcon color="#FD5" icon={faSplotch} /> : <></>}
              onClick={() => onSelectGame(game.id)}
            >
              <Text w="100%" textAlign="left" textOverflow="ellipsis" overflowX="hidden"
              whiteSpace="nowrap">{ game.name }</Text>
            </Button>
          ))}
        <Button background="white" border="2px solid slateblue" color="slateBlue" onClick={onCreate} p="4" rightIcon={<FontAwesomeIcon icon={faPlus} />} _hover={{ background: 'slateblue', color: 'white'}}>
            <Text>New game</Text>
        </Button>
      </Flex>
      <Stack className="menuUser" borderTop="1px solid #dedede" pt="2">
        <Flex background="white">
          <Avatar src="./logo.png" name={ username || "Your Name" } />
          <Flex direction="column">
            <Text fontSize="xl" ml="2">{ username || "Your Name" }</Text>
            <Text fontSize="sm" ml="2" color="orange">Free Account</Text>
          </Flex>
        </Flex>
        {/* <Stack my="2">
            <Button colorScheme="green" background="slateblue" w="100%" onClick={onSettings}>Upgrade plan</Button>
        </Stack> */}
        <Stack my="2">
            <Button w="100%" onClick={onDownload} leftIcon={<FontAwesomeIcon icon={faPuzzlePiece} />}>
              <Text w="100%">Download Plugin</Text>
            </Button>
        </Stack>
        <Stack mb="2">
          <Button w="100%" onClick={onSettings} leftIcon={<FontAwesomeIcon icon={faGear} />}>
            <Text w="100%">Settings</Text>
          </Button>
        </Stack>
        <Stack>
            <Button w="100%" onClick={homepage} leftIcon={<FontAwesomeIcon icon={faHouse} />}>
              <Text w="100%">Exit</Text>
            </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Menu;