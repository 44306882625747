import { FC, useState } from "react"

import { Container,
  Flex,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Button,
  Spinner
} from "@chakra-ui/react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGamepad, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import { useFormik } from "formik";

interface Props {
  onSubmit: (values:any) => void
}


const GameCreate: FC<Props> = ({ onSubmit }:Props) =>  {
  const [domainState,setDomainState]:[boolean|null|"loading",any] = useState(null)
  const [submitState,setSubmitState]:[boolean|null|"loading",any] = useState(null)
  const [queryTimer,setQueryTimer]:[number,any] = useState(0);

  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => handleFormSubmit(values)
  });

  const isFormValid = () => {
    if (!formik.values.name.length) return false
    else if (domainState !== true) return false
    else return true;
  }

  const clearQueryTimer = () => {
    clearTimeout(queryTimer)
    setDomainState(null);
  }

  const onNameChange = (formikEvent:any) => {
    formik.handleChange(formikEvent);
    clearQueryTimer()
    const value = formikEvent.target?.value;
    if (!value.replace(/[^0-9a-z]/gi, '').length) return;

    setQueryTimer(0)

    setQueryTimer(setTimeout(() =>  {

      setDomainState("loading")
      clearTimeout(queryTimer)
      setTimeout(() =>  { // TODO: API test name call then
        setDomainState(true)
      }, 3000)

    }, 1500)) // Query delay after keyup
  }

  const handleFormSubmit = (values:any) => {
    setSubmitState("loading")
    setTimeout(() => { // TODO: API create call then
      setSubmitState(true)
      onSubmit(values.name)
    },1000)
  }

  const nameFormatter = (name:string) => name.length ? name.toLowerCase().trim().replaceAll(' ', '-').replace(/[^0-9a-z-]/gi, '') : "name"

  return (
    <Container overflowY="auto" height="100vh">
      <Flex direction="column" borderRadius="16px" background="rgba(255,255,255,.5)" px="6">
        <Text as="h3" fontSize="3xl" mb="2">Publish new game</Text>
        <form onSubmit={formik.handleSubmit}>
          <Stack p="4" borderRadius="md" border="1px solid #ddd" w="100%">
            <Button as="a" href="./EtheRPGOnline_Deployer.js" download="EtheRPGOnline_Deployer" colorScheme="purple" background="slateblue" leftIcon={<FontAwesomeIcon icon={faFileArrowDown} />}>
              <span>Download</span>
              <span className="noMobile">&nbsp;"EtheRPGOnline_Deployer.js"</span>
              <span className="mobileOnly">&nbsp;Plugin</span>
            </Button>
            <Text><strong>1.</strong> <strong>Copy</strong> the file <strong>to</strong> your game plugin <strong>folder</strong></Text>
            <Text><strong>2.</strong> <strong>Enable</strong> the plugin <strong>in</strong> plugin <strong>Manager</strong></Text>
          </Stack>

          <Stack my="4" p="4" borderRadius="md" border="4px solid slateblue" w="100%">
            <FormControl isRequired>
              <FormLabel fontWeight="bold">3. Name your project</FormLabel>
              <Input id="name" value={formik.values.name} onChange={onNameChange} type="text" placeholder="Enter the name of your game..." />
              <FormErrorMessage>Name is required.</FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
            <Text fontWeight="bold">Your future game address</Text>
            <Text>This will be the address to play your game:</Text>
            <Flex w="100%" textAlign="left" lineHeight="40px" pr="0">
              <Text as="code" color="grey" mx="0" w="100%"><span className="noMobile">https://</span><span style={{color:"slateblue"}}>{ nameFormatter(formik.values.name) }</span>.etherpg.online</Text>
            </Flex>
            { domainState === "loading" && <Flex justify="flex-end">
              <Text align="right" color="orange" mr="2">Verifying availability...</Text>
              <Spinner color="orange" />
            </Flex>}
            { typeof domainState === "boolean" && <Text align="right" color={domainState === true ? "green" : "red"}>This name is{domainState === true ? "" : " not "} available!</Text>}
          </Stack>
          <Flex justify="end">
            { submitState === null && <Button className="CreateBtn" size="lg" colorScheme="green" background="slateblue" type="submit" disabled={!isFormValid()} rightIcon={<FontAwesomeIcon size="lg" icon={faGamepad} />}>Create</Button>}
            { submitState === "loading" && <Button size="lg" colorScheme="purple"  type="submit" disabled rightIcon={<Spinner color="whiteAlpha.500" />}>One sec...</Button> }
          </Flex>
        </form>
      </Flex>
    </Container>
  )
}

export default GameCreate;