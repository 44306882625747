import { FC, useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import { Container, Stack, Flex, Spacer, Text, Button } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll, faShieldAlt, faDice, faDragon, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'

import TopBar from '../components/TopBar';

import LoginModal from "../components/LoginModal"

import { AppState } from "../AppState"

interface Props {
  join?: boolean
  onLogin: () => void
}

const Home: FC<Props> = ({ join, onLogin }:Props) => {
  const [loginModal,setLoginModal] = useState(false)
  const [bgAttachment,setBgAttachment] = useState("fixed")

  const { username } = useContext(AppState);
  const navigate = useNavigate();
  const scroll = (y:number) => window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth'
  });

  useEffect(() => {
    if (isMobile) setBgAttachment('scroll')
  }, [setBgAttachment])
  
  useEffect(() => {
    if (join && !username) setLoginModal(true);
    else if (join && username) navigate('/dashboard')
    else if (loginModal && username) navigate('/dashboard')
  },[join,username,loginModal,setLoginModal,navigate])

  return (<>
      <TopBar links={[
        {text:'Get the Plugin', action: () => scroll(window.innerHeight)},
        {text:'Game Hosting', action: () => scroll(window.innerHeight * 2)},
      ]} onAccountClick={() => username ? navigate('/dashboard') : setLoginModal(true) } />
      <LoginModal isOpen={loginModal} onLogin={onLogin} onClose={() => setLoginModal(false)} />
      <Flex
        className="PageSection"
        minH="100vh"
        backgroundImage="url('./logo.png')"
        backgroundSize="cover"
        backgroundAttachment={bgAttachment}
        backgroundPosition="center"
        justify="center"
        direction="column"
      >
        <Flex className="landingPageOverlay" justify="center" direction="column" h="100vh">
          <Container w="1680px" maxW="80vw" borderRadius="xl">
            <Flex>
              <Stack>
                <Text as="h1" fontSize="5xl" my="4" fontWeight="bold" color="white">EtheRPG Online</Text>
                <Stack my="8">
                  <Text as="h2" fontSize="2xl"mb="4" fontWeight="bold" color="white">Share your fantastic&nbsp;world!</Text>
                  <Stack direction="column" color="white">
                    <Text fontSize="xl">Play your <strong>RPG Maker</strong> games directly <strong>on the web</strong>,</Text>
                    <Text fontSize="xl">Send to your friends in one click</Text>
                  </Stack>
                  <Flex pt="8" w="100%" justify="start">
                    <Stack>
                      <Button onClick={() => setLoginModal(true)} size={{md: "lg"}} height="64px" colorScheme="red" mx="auto" px="4" mb="4">Publish your game<span className="noMobile">&nbsp;now</span></Button>
                    </Stack>
                  </Flex>
                  <Flex>
                    <Stack>
                      <Text as="button" fontSize="lg" mx="auto" onClick={() => scroll(window.innerHeight)} color="white" cursor="pointer">Or <u>Learn more</u></Text>
                    </Stack>
                  </Flex>
                </Stack>
              </Stack>
            </Flex>
          </Container>
        </Flex>
      </Flex>
      <Stack className="PageSection" minH="100vh" background="skyblue" py="6" justify="center"
        backgroundImage="url('./Map012.png')"
        backgroundSize="cover"
        backgroundAttachment={bgAttachment}
        backgroundPosition="center"
      >
        <Container>
          <Flex direction="column" borderRadius="16px" background="whiteAlpha.800" px="6">
            <Text as="h2" fontSize="2xl" textAlign="center" mt="4" fontWeight="bold">Easier than ever</Text>
            <Flex my="4" justify="center">
              <FontAwesomeIcon icon={faPuzzlePiece} color="slateblue" size="6x" />
            </Flex>
            <Stack mb="4" textAlign="center" borderRadius="md" w="100%" fontSize="lg">
              <Text><strong>EtheRPG Online Deployer</strong> is the only <strong>plugin</strong> <br />to quickly <strong>upload your&nbsp;game</strong></Text>
              <Text py="3">100% compatible with <strong>RPG&nbsp;Maker</strong>&nbsp;<strong>MV</strong>&nbsp;and&nbsp;<strong>MZ</strong>!</Text>
              <Text fontWeight="bold" fontSize="xl">3 steps deployment</Text>
              <Flex justify="center">
                <Flex direction="column" textAlign="left" mx="auto" mb="4">
                  <Text>1. Enable one single plugin</Text>
                  <Text>2. Parameter just one setting</Text>
                  <Text>3. Click upload and it's done!</Text>
                </Flex>
              </Flex>
              <Flex justify="center">
                <Button size="lg" colorScheme="green" background="slateblue" onClick={() => setLoginModal(true)}>Start now</Button>
            </Flex>
            <Flex justifyContent="center">
              <Stack>
                <Text as="button" fontSize="lg" mx="auto" onClick={() => scroll(window.innerHeight * 2)} color="black" cursor="pointer">Or <u>Learn more</u></Text>
              </Stack>
            </Flex>
              {/* <Flex justify="center">
                <Button size="lg" border="1px solid slateblue" color="slateblue" onClick={() => scroll(window.scrollY + window.innerHeight * 1)}>Host your game now</Button>
              </Flex> */}
            </Stack>
          </Flex>
        </Container>
      </Stack>
      <Stack minH="100vh"
        className="PageSection"
        backgroundImage="url('./banner.png')"
        backgroundSize="cover"
        backgroundAttachment={bgAttachment}
        backgroundPosition="center"
        pt="6" pb="16" justify="center"
      >
        <Text as="h2" fontSize="3xl" textAlign="center" fontWeight="bold" color="white" py="8">Build your realm</Text>
        <Flex  w="100%" overflowX="auto" my="4" px="8">
            <Flex mx="auto" px="4">
              <Stack mr="4" minW="240px" w="100%" maxW="280px" p="4" borderRadius="md" color="white" background="blackAlpha.700">
                <Flex my="4" justify="center">
                  <FontAwesomeIcon icon={faShieldAlt} color="skyblue" size="6x" />
                </Flex>
                <Text fontWeight="bold">Free RPG</Text>
                <hr />
                <Text>Upload your <strong>RPG Maker</strong> projects to the <strong>web</strong></Text>
                <Text>Show your games to your friends in a few clicks <strong>for&nbsp;free</strong>!</Text>
                <Spacer />
                <Button onClick={() => setLoginModal(true)} colorScheme="messenger" size="lg" w="100%" textTransform="uppercase">Start for free</Button>
              </Stack>
              <Stack mr="4" minW="240px" w="100%" maxW="280px" p="4" borderRadius="md" color="white" background="blackAlpha.700">
              <Flex my="4" justify="center">
                <FontAwesomeIcon icon={faScroll} color="skyblue" size="6x" />
              </Flex>
                <Text fontWeight="bold">Premium RPG</Text>
                <hr />
                <Text>Send your games to the <strong>next level</strong> with EtheRPG Online</Text>
                <Text>Publishing your creations is now easier than ever</Text>
                <Text>With <strong>no advertising</strong>!</Text>
                <Spacer />
                <Button color="black" disabled>(coming soon)</Button>
              </Stack>
              <Stack mr="4" minW="240px" w="100%" maxW="280px" p="4" borderRadius="md" color="white" background="blackAlpha.700">
                <Flex my="4" justify="center">
                  <FontAwesomeIcon icon={faDice} color="skyblue" size="6x" />
                </Flex>
                <Text fontWeight="bold">MMORPG</Text>
                <hr />
                <Text>Create <strong>Massively Multiplayer Online</strong> games to play with your friends</Text>
                <Text>Organise <strong>special events</strong> and unique <strong>experiences</strong></Text>
                <Text>Manage your own <strong>domain names</strong></Text>
                <Spacer />
                <Button color="black" disabled>(coming soon)</Button>
              </Stack>
              <Stack minW="240px" w="100%" maxW="280px" p="4" borderRadius="md" color="white" background="blackAlpha.700">
                <Flex my="4" justify="center">
                  <FontAwesomeIcon icon={faDragon} color="skyblue" size="6x" />
                </Flex>
                <Text fontWeight="bold">Metaverse</Text>
                <hr />
                <Text>Your players own <strong>lands</strong> and parcels of your world</Text>
                <Text>You fully <strong>govern</strong> your realm and <strong>control</strong> your creation</Text>
                <Text>Your game items are <strong>interoperable</strong> accross all your RPGs</Text>
                <Spacer />
                <Button color="black" disabled>(coming soon)</Button>
              </Stack>
            </Flex>
        </Flex>
      </Stack>
      <Flex textAlign="center" background="black" color="white" justify="center"
        backgroundImage="url('./Map084.png')"
        backgroundSize="cover"
        backgroundPosition="center"
        borderTop="4px solid orange"
      >
        <Flex background="blackAlpha.800" py="16" color="white" w="100%" justify="center">
          <Stack w="1680px" maxW="80vw">
            <Flex>
              <Flex w="100%">
                <Stack textAlign="left" justify="flex-end">
                  <Stack mb="4">
                    <Text textAlign="left">i. This plugin is compatible with <strong>RPG Maker</strong> <strong>MV</strong> or <strong>MZ</strong></Text>
                    <Flex>
                      <Text textAlign="left"
                        as="button"
                        cursor="pointer"
                        textDecoration="underline"
                        onClick={() => window.open('https://www.rpgmakerweb.com/products/rpg-maker-mz','_blank')}
                      >Learn more<span className="hidden"> about RPG Maker</span></Text>
                    </Flex>
                  </Stack>
                  <Text align="left" as="small"><strong>EtheRPG Online</strong> is an indie development studio which is not related to any of the RPG Maker series, brands or companies.</Text>
                  <Text align="left"><strong>Copyright &copy; EtheRPG Online</strong>, by <Text as="button" textAlign="left" onClick={() => window.open('https://twitter.com/Andaroth','_blank')}><u>@Andaroth</u></Text> &amp; <Text as="button" textAlign="left" onClick={() => window.open('https://twitter.com/_Mouradif','_blank')}><u>@_Mouradif</u>. All rights reserved.</Text></Text>
                </Stack>
                <Spacer />
                <Stack justify="flex-end" ml="2">
                  <Stack height="100%">
                    <Flex justify="flex-end" lineHeight="40px" cursor="pointer" as="button" onClick={() => window.open('https://etherpg.online/discord','_blank')}>
                      <Text mr="2" className="noMobile">Reach us on</Text>
                      <Text px="2" borderRadius="md" background="slateblue" color="white">Discord</Text>
                    </Flex>
                    <Spacer />
                    <Stack pt="4">
                      <Flex justify="flex-end">
                        <Text as="button" textDecoration="underline" cursor="pointer" textAlign="right"
                          onClick={() => window.open('https://docs.google.com/document/d/1gPTQbeimnUTbn878ZpY6PdK6jwx_bGywcqK122yDzOU/edit?usp=sharing', '_blank')}
                        >Terms & EULA</Text>
                      </Flex>
                    </Stack>
                  </Stack>
                </Stack>
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </Flex>
  </>);
}

export default Home;