import { FC } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  placement?: "left" | "right"
  overlay?: boolean
  onClose: () => void
  children?: any
}

const SideBar: FC<Props> = ({ isOpen, placement, overlay = true, onClose, children }: Props) => {

  return(
    <>
      <Drawer
        isOpen={isOpen}
        placement={placement || "left"}
        onClose={onClose}
      >
        { overlay && <DrawerOverlay /> }
        <DrawerContent>
          <DrawerCloseButton zIndex="42" />
          <DrawerBody>
            { children }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SideBar;