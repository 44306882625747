import { FC, useState } from "react"

import { Container,
  Flex,
  Stack,
  Text,
  Button,
  Spacer
 } from "@chakra-ui/react"

 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

 interface Props {
  onLogout: (values:any) => void
 }


const Settings: FC<Props> = ({ onLogout }:Props) =>  {
  const [isPlanVisible,showPlan] = useState(false)

  return (
    <Container>
      <Flex direction="column" borderRadius="16px" background="rgba(255,255,255,.5)" px="6">
        <Text as="h3" fontSize="3xl" mb="2">Settings</Text>
        <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
          <Text fontSize="2xl" color="black" fontWeight="bold">Account</Text>
          <Button w="100%" onClick={onLogout}>Logout</Button>
        </Stack>
        <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
          <Text fontSize="2xl" color="black" fontWeight="bold">Info & Support</Text>
          <Button w="100%" as="a" href="https://docs.google.com/document/d/1gPTQbeimnUTbn878ZpY6PdK6jwx_bGywcqK122yDzOU/edit?usp=sharing" target="_blank">License & Agreements</Button>
          <Button as="a" href="https://etherpg.online/discord" target="_blank">Contact us on Discord</Button>
        </Stack>

        <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%"
          onClick={() => !isPlanVisible ? showPlan(true) : null}
          cursor={ !isPlanVisible ? "pointer" : "initial"}
        >
          <Flex onClick={() => isPlanVisible ? showPlan(false) : null} cursor={ isPlanVisible ? "pointer" : "inherit"}>
            <Text fontSize="2xl" fontWeight="bold">Game Hosting</Text>
            <Spacer />
            <Button background="transparent" onClick={() => showPlan(!isPlanVisible)} w="40px" borderRadius="50%">
              <FontAwesomeIcon icon={isPlanVisible ? faAngleUp : faAngleDown} />
            </Button>
          </Flex>
          { isPlanVisible && <Stack pt="2">
              <Stack as="button" onClick={(e) => e.preventDefault()} cursor="initial" mb="4" w="100%" p="4" borderRadius="md" background="slateblue" color="white">
                <Flex w="100%">
                  <Text fontWeight="bold" textAlign="left"><u>Free RPG</u> <i>($0/month)</i></Text>
                  <Spacer />
                  <Text textAlign="right" as="small">(Your current pricing)</Text>
                </Flex>
                <Text textAlign="left">Upload your <strong>RPG Maker</strong> projects to the <strong>web</strong></Text>
                <Text textAlign="left">Show your games to your friends in a few clicks <strong>for&nbsp;free</strong>!</Text>
                <Text textAlign="left"><i>Contains advertising!</i></Text>
                <Spacer />
              </Stack>
              <Stack as="button" onClick={(e) => e.preventDefault()} cursor="initial" mb="4"  w="100%" p="4" borderRadius="md" border="1px solid #ccc" background="white">
                <Text fontWeight="bold">Premium RPG</Text>
                <Text textAlign="left">Send your games to the <strong>next level</strong> with EtheRPG Online</Text>
                <Text textAlign="left">Publishing your creations is now easier than ever</Text>
                <Text textAlign="left">With <strong>no advertising</strong>!</Text>
                <Spacer />
                <Button w="100%" disabled>UPGRADE (coming soon)</Button>
              </Stack>
              <Stack as="button" onClick={(e) => e.preventDefault()} cursor="initial" mb="4" w="100%" p="4" borderRadius="md" border="1px solid #ccc" background="white">
                <Text fontWeight="bold">MMORPG</Text>
                <Text textAlign="left">Create <strong>Massively Multiplayer Online</strong> games to play with your friends</Text>
                <Text textAlign="left">Organise <strong>special events</strong> and unique <strong>experiences</strong></Text>
                <Text textAlign="left">Manage your own <strong>domain names</strong></Text>
                <Spacer />
                <Button w="100%" disabled>UPGRADE (coming soon)</Button>
              </Stack>
              <Stack as="button" onClick={(e) => e.preventDefault()} cursor="initial" w="100%" p="4" borderRadius="md" border="1px solid #ccc" background="white">
                <Text fontWeight="bold">Metaverse</Text>
                <Text textAlign="left">Your players own <strong>lands</strong> and parcels of your world</Text>
                <Text textAlign="left">You fully <strong>govern</strong> your realm and <strong>control</strong> your creation</Text>
                <Text textAlign="left">Your game items are <strong>interoperable</strong> accross all your RPGs</Text>
                <Spacer />
                <Button w="100%" disabled>UPGRADE (coming soon)</Button>
              </Stack>
            </Stack>
          }
        </Stack>
      </Flex>
    </Container>
  )
}

export default Settings;