import { FC, useState, useRef } from "react"

import { Container,
  Flex,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spacer,
  Button,
  Switch,
  Spinner
} from "@chakra-ui/react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareFromSquare, faUpload, faKey, faCircleCheck, faWarning } from '@fortawesome/free-solid-svg-icons'

import { Game } from "../AppState"

interface Props {
  selectedGame: Game
  isDebug?: boolean
  onSettings: (values:any) => void
  onUpdateGameState: (game:Game) => void
}


const GameView: FC<Props> = ({ selectedGame, isDebug, onSettings, onUpdateGameState }:Props) =>  {
  const [generatedToken,setGeneratedToken]:[string,any] = useState("")
  const [errorMessage,setErrorMessage]:[string|null,any] = useState(null)
  const [tokenState,setTokenState]:[boolean|null|"loading",any] = useState(null)
  const [copiedAddressToken,setCopiedAddress] = useState(false)
  const [copiedToken,setCopiedToken] = useState(false)

  const copyClipboard = async (text:string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      const textarea = document.createElement('textarea')
      textarea.setAttribute('readonly', "true");
      textarea.setAttribute('contenteditable', "true");
      textarea.style.position = "fixed";
      textarea.value = text;
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      document.execCommand('copy', true, text);
      textarea.remove();
  }
  

  // const range = document.createRange();
  // range.selectNodeContents(textarea);

  // const sel = window.getSelection();
  // if (sel) {
  //   sel.removeAllRanges();
  //   sel.addRange(range);

  //   textarea.setSelectionRange(0, textarea.value.length);
  //   document.execCommand('copy');
  // } 
  }

  const handleCopyAddress = () => {
    copyClipboard(selectedGame.path)
    setCopiedAddress(true);
    setCopiedToken(false);
    setTimeout(() => {
      setCopiedAddress(false)
    }, 3000);
  }

  const handleCopyToken = (token:string) => {
    copyClipboard(token);
    setCopiedToken(true);
    setCopiedAddress(false);
    setTimeout(() => {
      setCopiedToken(false)
    }, 3000);
  }

  const handleRevealToken = (token:string) => {
    setGeneratedToken(token); // show in UI
    handleCopyToken(token); // copy to clipboard
  }

  const generateToken = async () => {
    if (!generatedToken?.length && !selectedGame.token?.length) {
      setTokenState("loading");
      setErrorMessage(null)
      try {
        setTimeout(() => { // await fetch api token .then =>
          handleCopyToken("50M3R4ND0M");

          onUpdateGameState(Object.assign(selectedGame,{ token: "50M3R4ND0M" })); // keep in app short memory
          setTokenState(1)
        }, 1000);
      } catch (error) {
        setErrorMessage(error);
        setTokenState(0);
      }
    } else {
      handleCopyToken(generatedToken || selectedGame.token || "");
      setTokenState(1);
    }
  }

  return (
    <Container>
      <Flex direction="column" borderRadius="16px" background="rgba(255,255,255,.5)" px="6">
        <Text as="h3" fontSize="3xl" mb="2" textAlign="center">{ selectedGame.name }</Text>
        <Stack mb="4" p="4" borderRadius="md" border="4px solid slateblue" w="100%">
          <Flex>
            <FontAwesomeIcon icon={faShareFromSquare} />
            <Text fontWeight="bold" ml="2">URL to play:</Text>
            { copiedAddressToken && <Text as="small" lineHeight="24px" ml="2" fontWeight="bold">Copied to clipboard!</Text> }
          </Flex>
            <Button
              p="2"
              borderRadius="md"
              overflowX="auto"
              cursor="pointer"
              colorScheme="purple"
              whiteSpace="nowrap"
              background="slateblue"
              maxW="calc(100vw - 114px)"
              fontFamily="monospace"
              justifyContent="left"
              onClick={handleCopyAddress}
            >
              <Text align="left">{ selectedGame.path }</Text>
            </Button>
            <Text as="small" color="blackAlpha.700" mt="0" ml="1" align="right">(click to copy)</Text>
          <Stack>
            <Text>Send this URL to your friends to share your game!</Text>
          </Stack>
        </Stack>
        <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
          <Flex>
            <FontAwesomeIcon icon={faUpload} />
            <Text color="black" fontWeight="bold" ml="2">Upload TOKEN:</Text>
            { copiedToken && <Text as="small" lineHeight="24px" ml="2" fontWeight="bold">Copied to clipboard!</Text> }
          </Flex>
          <Button
            p="2"
            borderRadius="md"
            border="1px solid #ccc !important"
            overflowX="auto"
            whiteSpace="nowrap"
            cursor="pointer"
            maxW="calc(100vw - 114px)"
            disabled={!generatedToken.length && !selectedGame.token}
            fontFamily="monospace"
            onClick={() => handleRevealToken(selectedGame.token || "")}
          >{ generatedToken.length ? generatedToken : selectedGame.token ? "Click to reveal" : "Only 1 token per hour" }</Button>
          { tokenState === null && <Button colorScheme="green" onClick={generateToken} fontWeight="bold" rightIcon={<FontAwesomeIcon icon={faKey} />}>1. {selectedGame.token ? "Copy" : "Generate"} token</Button>}
          { tokenState === "loading" && <Button colorScheme="orange" disabled rightIcon={<Spinner color="white" />}>1. Loading... </Button>}
          { tokenState === 0 && <Button colorScheme="orange" opacity="1 !important" disabled fontWeight="bold" rightIcon={<FontAwesomeIcon icon={faWarning} />}>You cannot get a token</Button>}
          { tokenState === 1 && <Button colorScheme="whatsapp" onClick={generateToken} fontWeight="bold" rightIcon={<FontAwesomeIcon icon={faCircleCheck} />}>1. Copy token</Button>}
          { errorMessage && <Text fontStyle="italic" textAlign="right">{ errorMessage }</Text>}
          <Stack>
            <Text><strong>2.</strong> <strong>Replace</strong> the <strong>token</strong> in the plugin parameters</Text>
            <Text><strong>3.</strong> Click <strong>Upload</strong> in your game Title Screen</Text>
          </Stack>
        </Stack>
        {/* ------- PREMIUM BELOW ------------------------------------------------- */}
        { isDebug && <>
          <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
            <Text color="black" fontWeight="bold">Game mode:</Text>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='mmorpgGame' mb='0'>
                <Text>Massively Multiplayer <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "initial"
                  }} onClick={onSettings}>(Upgrade&nbsp;Plan)</span></Text>
              </FormLabel>
              <Spacer />
              <Switch disabled id='mmorpgGame' />
            </FormControl>
            <Button disabled>Players Database</Button>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor='tokenization' mb='0'>
                <Text>Enable Metaverse Mode <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "initial"
                  }} onClick={onSettings}>(Upgrade&nbsp;Plan)</span></Text>
              </FormLabel>
              <Spacer />
              <Switch disabled id='tokenization' />
            </FormControl>
            <Button disabled>Metaverse Settings</Button>
          </Stack>
          <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
            <Text color="black" fontWeight="bold">DNS setting: <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontStyle: "italic",
                fontWeight: "initial"
              }} onClick={onSettings}>(Upgrade&nbsp;Plan)</span></Text>
            <FormControl display='flex' alignItems='center'>
              <FormLabel><Text>DNS1</Text></FormLabel>
              <Input id="dns1" type="text" value="ns1.etherpg.online" disabled />
            </FormControl>
            <FormControl display='flex' alignItems='center'>
              <FormLabel><Text>DNS2</Text></FormLabel>
              <Input id="dns1" type="text" value="ns2.etherpg.online" disabled />
            </FormControl>
            <FormControl display='flex' alignItems='center'>
              <FormLabel><Text>DNS3</Text></FormLabel>
              <Input id="dns1" type="text" value="ns3.etherpg.online" disabled />
            </FormControl>
            <Flex justify="end">
              <Button type="submit" disabled>Save</Button>
            </Flex>
          </Stack>
        </>}
      </Flex>
    </Container>
  )
}

export default GameView;