import { createContext, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Admin from './pages/Admin';

import { StateType, initialSession, Game, AppState } from './AppState';

export default function App() {
  const [session,setSession] = useState(initialSession);
  const handleLogin = (session?:StateType) => {
    console.log('handleLogin')
    try {
      const _sessionTest = session?.username ? session : {
        username: "Axel Anda",
        jwt: "fake",
        games: window.location.href.includes('?debug') ? [
          {
            id: 0,
            name: "RPG Verse",
            path: "https://rpgverse.com",
            token: "sj71ge3DG"
          },
          {
            id: 1,
            name: "EtheRPG Online",
            path: "https://etherpg.online",
            token: "80jD4v60x"
          },
        ] : [],
        isDebug: window.location.href.includes('?debug')
      } 
      setSession(_sessionTest);
      localStorage.setItem('session', JSON.stringify(_sessionTest));
    } catch (error) {
      console.error(error);
      setSession(initialSession);
    }
  }

  const handleGameCreated = (name:string) => {
    const mutateSession = {
      username: session.username,
      jwt: session.jwt,
      games: [...session.games,{
        name: name.trim(),
        id: session.games.length,
        path: "https://"
          + name.toLowerCase().trim().replaceAll(' ', '-').replace(/[^0-9a-z-]/gi, '')
          + ".etherpg.online"
      }],
      isDebug: session.isDebug || window.location.href.includes('?debug')
    };
    setSession(mutateSession);
    localStorage.setItem('session', JSON.stringify(mutateSession));
  }

  const handleUpdateGameState = (game:Game) => {
    let mutateGames = session.games.map((g:Game) => {
      if (g.id === game.id) g = game
      return g;
    });
    const mutateSession = {
      username: session.username,
      jwt: session.jwt,
      games: [...mutateGames],
      isDebug: session.isDebug || window.location.href.includes('?debug')
    };
    setSession(mutateSession); // refresh UI
  }

  const handleLogout = () => {
    localStorage.removeItem('session');
    setSession(initialSession);
  }

  if (localStorage.getItem('session')) {
    try {
      const storage = JSON.parse(localStorage.getItem('session') || "")
      if (storage.username && storage.jwt && storage.games) {
        if (!session.username) handleLogin({
          username: storage.username,
          jwt: storage.jwt,
          games: storage.games,
          isDebug: session.isDebug || window.location.href.includes('?debug')
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <BrowserRouter>
      <ChakraProvider>
        <AppState.Provider value={session}>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/dashboard" element={<Dashboard onCreated={handleGameCreated} onUpdateGameState={handleUpdateGameState} onLogout={handleLogout} />} />
            <Route path="/" element={<Home onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AppState.Provider>
      </ChakraProvider>
    </BrowserRouter>
  );
}