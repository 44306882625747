import { FC, useContext, useEffect, useState } from "react";
import { useFormik } from "formik";

import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

import Menu from "../components/Menu"
import SideBar from "../components/CustomDrawer"
import GameCreate from "../components/GameCreate";
import GameView from "../components/GameView";
import Settings from "../components/Settings";
import Downloads from "../components/Downloads";

import {
  Container,
  Stack,
  Flex,
  Text,
  Button
} from "@chakra-ui/react";

import { AppState } from "../AppState"

interface Props {
  onCreated: (g:any) => void
  onLogout: () => void
  onUpdateGameState: (e:any) => void
}

const Dashboard: FC<Props> = ({ onCreated, onLogout, onUpdateGameState }:Props) => {
  const navigate = useNavigate()
  const { username, games } = useContext(AppState);
  const [mobileMenuOpen,setMobileMenuOpened] = useState(false)
  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => handleSubmit(values)
  });

  const [selectId,setSelect]:[number|null,any] = useState(games.length ? 0 : -1);

  const selectedGame = () => games[selectId];

  const handleSelectGame = (id:number) => {
    setMobileMenuOpened(false)
    if (selectId === id) return;
    setSelect(null);
    requestAnimationFrame(() => setSelect(id));
  }

  const handleCreate = () => {
    setMobileMenuOpened(false)
    if (selectId === -1) return;
    setSelect(-1);
  }
  const handleSettings = () => {
    setMobileMenuOpened(false)
    if (selectId === -2) return;
    setSelect(-2);
  }
  const handleDownload = () => {
    setMobileMenuOpened(false)
    if (selectId === -3) return;
    setSelect(-3);
  }

  const handleSubmit = (values:any) => {
    onCreated(values);
    formik.resetForm();
    setSelect(games.length)
  }

  useEffect(() => {
    if (!username) {
      navigate('/')
      requestAnimationFrame(() => window.scrollTo({ top: 0, left: 0 }));
    }
  });

  return (
    <>
      <Stack className="mobileOnly">
        <Button position="fixed" background="rgba(255,255,255,.3)" color="black" border="1px solid #ccc" right="8px" top="8px" h="40px" lineHeight="40px" borderRadius="md" onClick={() => setMobileMenuOpened(true)} rightIcon={<FontAwesomeIcon icon={faBars} />} zIndex="100">
            <Text fontSize="lg">Menu</Text>
        </Button>
        <SideBar
          isOpen={mobileMenuOpen}
          placement="right"
          onClose={() => setMobileMenuOpened(false)}
        >
          <Menu selectId={selectId} onSelectGame={(id) => handleSelectGame(id)} onCreate={handleCreate} onDownload={handleDownload} onSettings={handleSettings} />
        </SideBar>
      </Stack>
      <Flex>
        <Flex
          position="fixed"
          py="4"
          px="8"
          height="100vh"
          direction="column"
          borderRight="1px solid #eee"
          background="white"
          w="320px"
          className="noMobile"
        >
          <Menu selectId={selectId} onSelectGame={(id) => handleSelectGame(id)} onCreate={handleCreate} onDownload={handleDownload} onSettings={handleSettings} />
        </Flex>

        <Flex className="dashboardInner" direction="column" grow="1" my="2" justify="center">
          { selectedGame() ? <GameView selectedGame={selectedGame()} onSettings={handleSettings} onUpdateGameState={onUpdateGameState} />
            : selectId === -1 ? <GameCreate onSubmit={handleSubmit} />
            : selectId === -2 ? <Settings onLogout={onLogout} />
            : selectId === -3 ? <Downloads />
          : null }
        </Flex> 
      </Flex>
    </>
  )
};

export default Dashboard;