import {
  Container,
  Flex,
  Text,
  Stack,
  Button
} from "@chakra-ui/react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const Downloads = () => {
  return (
    <Container>
      <Flex direction="column" borderRadius="16px" background="rgba(255,255,255,.5)" px="6">
        <Text as="h3" fontSize="3xl" mb="2">Download</Text>
        <Stack mb="4" p="4" borderRadius="md" border="1px solid #ddd" w="100%">
          <Text fontSize="2xl" fontWeight="bold">EtheRPG Online Deployer</Text>
          <Button as="a" href="./EtheRPGOnline_Deployer.js" download="EtheRPGOnline_Deployer" colorScheme="whatsapp" leftIcon={<FontAwesomeIcon icon={faFileArrowDown} />}>
            <span>Download Plugin</span>
          </Button>
          <Text><strong>1.</strong> <strong>Copy</strong> the file <strong>to</strong> your game plugin <strong>folder</strong></Text>
          <Text><strong>2.</strong> <strong>Enable</strong> the plugin <strong>in</strong> plugin <strong>Manager</strong></Text>
          <Text><strong>3.</strong> <strong>Replace</strong> the <strong>token</strong> in the plugin parameters</Text>
        </Stack>
        <Stack px="4"
          opacity="0.5"
        >
          <Flex justify="center">
            <Stack>
              <Text textAlign="center">This plugin is only compatible with <strong>RPG Maker</strong> <strong>MV</strong> or <strong>MZ</strong>.</Text>
              <Flex justify="center">
                <Text
                  as="button"
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => window.open('https://www.rpgmakerweb.com/products/rpg-maker-mz','_blank')}
                >Learn more<span className="hidden"> about RPG Maker</span></Text>
              </Flex>
            </Stack>
          </Flex>
        </Stack>
      </Flex>
    </Container>
  )
}

export default Downloads